import type { GetStaticProps, NextPage } from "next";
import MainView from "@components/views/MainView/view";
import { allSettled, fork, serialize } from "effector";
import Head from "next/head";
import { GOOGLE_VERIFICATION_ID, YANDEX_VERIFICATION_ID } from "@config/index";
import { COLLECTION_COURSES_MAIN_PAGE_CONFIG } from "@shared/constants/options";
import { getIntellectualBeneficenceCollectionEvent } from "@models/collections/intellectualBeneficence";
import { getTheMostPopularCourses } from "@components/views/MainView/model/public";
import { getBestCoursesEvent } from "@components/views/MainView/model/private";
import { prepareUrlWithHost } from "@shared/functions/makeUrlWithHost";

const title = "Selfschool — Платформа для онлайн-обучения, образовательный курсы";
const description =
	"Образовательная онлайн-платформа Selfschool. Дизайн, программирование, обучение фотографии, иностранным языкам и другие направления. Сотрудничество с авторами курсов.";

const IndexPage: NextPage = () => {
	const path = prepareUrlWithHost();
	return (
		<>
			<Head>
				<title>{title}</title>
				<meta name="description" content={description} />
				<link rel="canonical" href={path} />
				<meta name="yandex-verification" content={YANDEX_VERIFICATION_ID} />
				<meta name="google-site-verification" content={GOOGLE_VERIFICATION_ID} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
			</Head>
			<MainView />
		</>
	);
};

export const getStaticProps: GetStaticProps = async () => {
	const scope = fork();

	await allSettled(getBestCoursesEvent, {
		scope,
		params: COLLECTION_COURSES_MAIN_PAGE_CONFIG,
	});

	await allSettled(getIntellectualBeneficenceCollectionEvent, {
		scope,
		params: COLLECTION_COURSES_MAIN_PAGE_CONFIG,
	});

	await allSettled(getTheMostPopularCourses, {
		scope,
		params: {
			...COLLECTION_COURSES_MAIN_PAGE_CONFIG,
			sortBy: "popularity",
		},
	});

	return {
		props: {
			initialState: serialize(scope),
		},
		revalidate: 30,
	};
};
export default IndexPage;
