import { getTheMostPopularCourses } from "@components/views/MainView/model/public";
import { sample } from "effector";
import { getCourses, getCoursesFx } from "@models/courses/getCourses";
import {
	$bestCourses,
	$theMostPopularCoursesResponse,
	getBestCoursesEvent,
	getBestCoursesFx,
	updateTheMostPopularCourses,
} from "@components/views/MainView/model/private";

sample({
	clock: getCoursesFx.doneData,
	source: $theMostPopularCoursesResponse,
	target: $theMostPopularCoursesResponse,
	fn: (state, payload) => {
		if (state !== null) {
			return {
				...payload,
				items: [...state.items, ...payload.items],
			};
		}

		return payload;
	},
});

sample({
	clock: [getTheMostPopularCourses, updateTheMostPopularCourses],
	target: getCourses,
});

sample({
	clock: getBestCoursesEvent,
	target: getBestCoursesFx,
});

sample({
	clock: getBestCoursesFx.doneData,
	source: $bestCourses,
	target: $bestCourses,
	fn: (state, payload) => {
		if (state === null) {
			return payload;
		}

		return {
			...payload,
			items: [...state.items, ...payload.items],
		};
	},
});
