import { createEffect, createEvent, sample } from "effector";
import CoursesService from "../../../services/api/courses/CoursesService";
import { GetBestCoursesParams } from "src/services/api/courses/typedef";

export const getBestCoursesCompilationEvent = createEvent<GetBestCoursesParams>();

export const getBestCoursesCompilationFx = createEffect(async (params: GetBestCoursesParams) => {
	const { data } = await CoursesService.getBestCourses(params);

	return data;
});

sample({
	clock: getBestCoursesCompilationEvent,
	target: getBestCoursesCompilationFx,
});
