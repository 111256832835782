import { createEffect, createEvent, forward, sample } from "effector";
import { AxiosError } from "axios";
import { ServerResponse } from "http";
import CollectionsService from "../../../services/api/collections/CollectionsService";
import {
	IntellectualBeneficenceCollection,
	IntellectualBeneficenceParams,
} from "@models/collections/intellectualBeneficence/typedef";
import { $intellectualBeneficenceCollection } from "@models/collections/public";

export const getIntellectualBeneficenceCollectionEvent = createEvent<IntellectualBeneficenceParams>();

export const getIntellectualBeneficenceCollectionFx = createEffect<
	IntellectualBeneficenceParams,
	IntellectualBeneficenceCollection,
	AxiosError<ServerResponse>
>(async ({ page, limit }) => {
	const { data } = await CollectionsService.getIntellectualBeneficenceCollection(limit, page);

	return data;
});

forward({
	from: getIntellectualBeneficenceCollectionEvent,
	to: getIntellectualBeneficenceCollectionFx,
});

sample({
	clock: getIntellectualBeneficenceCollectionFx.doneData,
	source: $intellectualBeneficenceCollection,
	target: $intellectualBeneficenceCollection,
	fn: (state, payload) => {
		if (state !== null) {
			return {
				...payload,
				courses: [...state?.courses, ...payload.courses],
			};
		}

		return payload;
	},
});
