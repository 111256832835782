import s from "./styles.module.sass";
import laptopAndMobileConstructorSrc from "../../../../../../../public/images/laptopAndMobileConstructorMainPage.png";
import Image from "next/image";
import { FC } from "react";
import { useStore } from "effector-react";
import { ROUTES } from "../../../../../../constants/ROUTES";
import CustomLink from "../../../../../UI/CustomLink";
import { $isAuth } from "../../../../../../process/authProcess/model/public";

const Constructor: FC = () => {
	const isAuth = useStore($isAuth);

	const linkText = "Поделиться знанием".toUpperCase();

	return (
		<section className={s.constructorBlock}>
			<div className={s.info}>
				<h2 className={s.title}>Хотите поделиться знаниями?</h2>
				<p className={s.description}>
					Создайте собственную обучающую программу, запишите авторскую лекцию или подкаст с приглашенными
					специалистами, поделитесь инструкцией, гайдом, рецензией, обзором или статьей — выбирайте подходящий
					формат или предложите новый! Мы открыты вашим идеям. Помогайте другим изучать новые темы и
					зарабатывайте с помощью Selfschool.
				</p>
				<div className={s.buttonWrapper}>
					<CustomLink href={isAuth ? ROUTES.INSTRUCTOR_PANEL_COURSES : ROUTES.LOGIN} text={linkText} />
				</div>
			</div>
			<div className={s.constructorImageWrapper}>
				<Image
					objectFit={"contain"}
					layout={"fill"}
					src={laptopAndMobileConstructorSrc}
					className={s.constructorImage}
					alt="Конструктор"
				/>
			</div>
		</section>
	);
};

export default Constructor;
