export const INTELLECTUAL_BENEFICENCE_SECTION_PARAGRAPHS = [
	"Мы хотим сделать знания доступными для всех - общаемся, собираем кейсы, советы от настоящих экспертов и лидеров из разных отраслей. Полученные материалы обладают огромной ценностью: спикеры делятся не теорией, а практикой, своим опытом, который помог им добиться карьерных высот, реализоваться и стать успешными. Все материалы Интеллектуальной благотворительности распространяются бесплатно.",
	'Мы верим, что вовремя полученные знания могут сохранить ресурсы, открыть новые возможности и даже спасти. Преумножая добрые дела, в знак благодарности за полезные и интересные видеоматериалы, которыми поделились наши авторы, вы можете перечислить любую сумму в Фонд помощи детям и молодежи "Обнажённые сердца". Сделать это возможно, перейдя по QR-коду, размещенному в видео автора. Мы будем очень признательны и воодушевлены.',
];

export const POPULAR_COURSES_SECTION_PARAGRAPHS = [
	"Популярно – это  ярко, харизматично, интересно и, главное,  актуально. Чтобы безнадежно не отстать от времени, точно знать, что будет востребовано не только сегодня, но и завтра, стоит подсмотреть, что смотрят другие.",
];

export const SELFSCHOOL_RECOMMENDED_COURSES_PARAGRAPHS = [
	"Среди сотен уникальных экспертов и полезных обучающих программ легко запутаться. Мы предлагаем вам найти  выход из этого лабиринта. Мы уверены, что лучше знать необходимое, чем многое.",
];

export const DIALOG_OF_GENERATIONS_SECTION_PARAGRAPHS = [
	"Диалог поколений - новый продукт команды Selfschool.",
	"Непривычный формат, в котором не стоит искать истину. В нем нет единственного решения или верного знания. Есть приглашение к участию, поиску, определению для себя самого, что есть правда на твой собственный взгляд. Через диалог мы пытаемся договориться о важном. О том, что сближает и объединяет людей разных поколений, что составляет основу настоящей школы, которую каждый с нашей помощью строит себе сам. ",
	"Мы полезны для детей, родителей и родителей родителей. Для всех и каждого, кто старается быть, а не казаться, думает и рефлексирует, ошибается, но пытается сделать что-то сам. В нашем арсенале диалоги и мнения. Разные точки зрения. Не только взрослых, но и детей. Участвуй, ищи, спорь, пробуй сам.",
];
