import style from "./styles.module.sass";
import clsx from "clsx";

type CoursesSectionDescriptionProps = {
	title: string;
	paragraphs: Array<string>;
};

const CoursesSectionDescription = ({ title, paragraphs }: CoursesSectionDescriptionProps) => {
	return (
		<div className={style.wrapper}>
			<h3 className={clsx(style.text, style.title)}>{title}</h3>
			{paragraphs.map((paragraph, index) => {
				return (
					<p className={style.text} key={index}>
						{paragraph}
					</p>
				);
			})}
		</div>
	);
};

export default CoursesSectionDescription;
