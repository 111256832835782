import { attach, createDomain } from "effector";
import { UpdateTheMostPopularCoursesParams } from "@components/views/MainView/model/typedef";
import { getBestCoursesCompilationFx } from "@models/courses/getBestCoursesCompilation";
import { GetBestCoursesParams, CoursesList } from "../../../../services/api/courses/typedef";

const mainView = createDomain("main-view");

export const $theMostPopularCoursesResponse = mainView.store<CoursesList | null>(null);

export const updateTheMostPopularCourses = mainView.event<UpdateTheMostPopularCoursesParams>();

export const getBestCoursesEvent = mainView.event<GetBestCoursesParams>();

export const $bestCourses = mainView.store<CoursesList | null>(null);

export const getBestCoursesFx = attach({
	effect: getBestCoursesCompilationFx,
});
