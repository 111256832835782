import { createGate, useGate, useStore } from "effector-react";
import Constructor from "./parts/Constructor";
import s from "./styles.module.sass";
import { FC } from "react";
import FirstSection from "./parts/FirstSection";
import {
	INTELLECTUAL_BENEFICENCE_SECTION_PARAGRAPHS,
	POPULAR_COURSES_SECTION_PARAGRAPHS,
	SELFSCHOOL_RECOMMENDED_COURSES_PARAGRAPHS,
} from "@shared/constants/arrays";
import NeuralQAModal from "../../../../features/NeuralQAModal/view/entries/NeuralQAModal";
import CoursesListing from "../../../../shared/UI/CoursesListing";
import InfoCourseCard from "@components/UI/InfoCourseCard";
import { CourseList } from "src/services/api/courses/typedef";
import CoursesSectionDescription from "src/components/views/MainView/view/parts/CoursesSectionDescription";

import { useEvent } from "effector-react/scope";
import {
	getIntellectualBeneficenceCollectionEvent,
	getIntellectualBeneficenceCollectionFx,
} from "@models/collections/intellectualBeneficence";
import { $intellectualBeneficenceCollection } from "@models/collections/public";
import { COLLECTION_COURSES_MAIN_PAGE_CONFIG } from "@shared/constants/options";
import "../model/init";
import {
	$bestCourses,
	$theMostPopularCoursesResponse,
	getBestCoursesEvent,
	getBestCoursesFx,
	updateTheMostPopularCourses,
} from "@components/views/MainView/model/private";
import { getCoursesFx } from "@models/courses/getCourses";
import PurchasedCourseListing from "../../../../features/PurchasedCourses/view";
import { $isAuth } from "../../../../process/authProcess/model/public";

const PAGINATION_STEP = 1;

const MainView: FC = () => {
	const bestCourses = useStore($bestCourses);
	const intellectualBeneficenceCollection = useStore($intellectualBeneficenceCollection);
	const isBestCoursesLoading = useStore(getBestCoursesFx.pending);
	const isIntellectualBeneficenceCollectionLoading = useStore(getIntellectualBeneficenceCollectionFx.pending);

	const theMostPopularCoursesResponse = useStore($theMostPopularCoursesResponse);
	const getTheMostPopularCourses = useEvent(updateTheMostPopularCourses);
	const isTheMostPopularCoursesLoading = useStore(getCoursesFx.pending);

	const getBestCourses = useEvent(getBestCoursesEvent);
	const getIntellectualBeneficenceCollection = useEvent(getIntellectualBeneficenceCollectionEvent);

	const authUserGate = createGate();

	const isAuth = useStore($isAuth);

	useGate(authUserGate);

	const getMoreTheMostPopularCourses = () => {
		if (theMostPopularCoursesResponse !== null) {
			getTheMostPopularCourses({
				page: theMostPopularCoursesResponse.currentPage + PAGINATION_STEP,
				sortBy: "popularity",
				limit: COLLECTION_COURSES_MAIN_PAGE_CONFIG.limit,
			});
		}
	};
	const updateBestCoursesCollection = () => {
		let page = PAGINATION_STEP;

		if (bestCourses !== null) {
			page = bestCourses.currentPage + PAGINATION_STEP;
		}

		getBestCourses({
			page,
			limit: COLLECTION_COURSES_MAIN_PAGE_CONFIG.limit,
		});
	};

	const updateIntellectualBeneficenceCollection = () => {
		if (intellectualBeneficenceCollection !== null) {
			getIntellectualBeneficenceCollection({
				page: intellectualBeneficenceCollection.currentPage + PAGINATION_STEP,
			});
		}
	};

	return (
		<>
			<FirstSection />
			<NeuralQAModal />
			<section className={s.wrapper}>
				<div className={s.innerPadding}>
					{isAuth && <PurchasedCourseListing />}
					{intellectualBeneficenceCollection !== null &&
						intellectualBeneficenceCollection.courses?.length !== 0 && (
							<CoursesListing<CourseList>
								title="Интеллектуальная благотворительность"
								courses={intellectualBeneficenceCollection.courses}
								descriptionComponent={
									<CoursesSectionDescription
										paragraphs={INTELLECTUAL_BENEFICENCE_SECTION_PARAGRAPHS}
										title={"Знания, которым нет цены."}
									/>
								}
								pagination={{
									currentPage: intellectualBeneficenceCollection.currentPage,
									isCoursesLoading: isIntellectualBeneficenceCollectionLoading,
									totalPage: intellectualBeneficenceCollection.totalPage,
									onShowMoreButtonClick: updateIntellectualBeneficenceCollection,
								}}
								courseComponentRenderer={(course, key) => {
									return <InfoCourseCard course={course} withMaxHeight isFill key={key} />;
								}}
							/>
						)}
					{theMostPopularCoursesResponse && theMostPopularCoursesResponse.items.length !== 0 && (
						<CoursesListing<CourseList>
							title="Самые популярные курсы"
							courses={theMostPopularCoursesResponse.items}
							descriptionComponent={
								<CoursesSectionDescription
									paragraphs={POPULAR_COURSES_SECTION_PARAGRAPHS}
									title={"Знания, в которых есть будущее."}
								/>
							}
							pagination={{
								currentPage: theMostPopularCoursesResponse.currentPage,
								isCoursesLoading: isTheMostPopularCoursesLoading || false,
								totalPage: theMostPopularCoursesResponse.totalPage,
								onShowMoreButtonClick: getMoreTheMostPopularCourses,
							}}
							courseComponentRenderer={(course, key) => {
								return <InfoCourseCard withMaxHeight course={course} isFill key={key} />;
							}}
						/>
					)}
					{bestCourses?.items && bestCourses.items?.length !== 0 && (
						<CoursesListing<CourseList>
							title="Selfschool рекомендует"
							courses={bestCourses.items}
							pagination={{
								currentPage: bestCourses.currentPage,
								isCoursesLoading: isBestCoursesLoading,
								totalPage: bestCourses.totalPage,
								onShowMoreButtonClick: updateBestCoursesCollection,
							}}
							descriptionComponent={
								<CoursesSectionDescription
									paragraphs={SELFSCHOOL_RECOMMENDED_COURSES_PARAGRAPHS}
									title={"Знания, которым всегда найдется применение."}
								/>
							}
							courseComponentRenderer={(course, key) => {
								return <InfoCourseCard withMaxHeight course={course} isFill key={key} />;
							}}
						/>
					)}
				</div>

				<Constructor />
			</section>
		</>
	);
};

export default MainView;
