import { $api } from "../index";
import { routes } from "../routes";
import { COLLECTIONS_IDS } from "../../../constants/COLLECTIONS_IDS";
import { CollectionWithCourseListResponse } from "@generated/models/CollectionWithCourseListResponse";
import { GetCollectionsRes } from "@generated/models/GetCollectionsRes";

class CollectionsService {
	static async getCollection(id: number, limit = 4, page = 1) {
		return await $api.get<GetCollectionsRes>(
			`${routes.collections}/${id}/courses?limit=${limit}&page=${page}`
		);
	}

	static async getIntellectualBeneficenceCollection(limit = 4, page = 1) {
		return await $api.get<CollectionWithCourseListResponse>(
			`${routes.collections}/${COLLECTIONS_IDS.INTELLECTUAL_BENEFICENCE_MAIN}/courses?limit=${limit}&page=${page}`
		);
	}

	static async getDialogGenerationsCollection(limit = 4, page = 1) {
		return await $api.get(
			`${routes.collections}/${COLLECTIONS_IDS.GENERATIONS_DIALOG}/courses?limit=${limit}&page=${page}`
		);
	}
}

export default CollectionsService;
