import { createEffect, createEvent, sample } from "effector";
import CoursesService from "../../../services/api/courses/CoursesService";
import { $getCoursesResponse } from "@models/courses/public";
import { CoursesList, GetCoursesParams } from "../../../services/api/courses/typedef";
import { AxiosError } from "axios";

export const getCourses = createEvent<GetCoursesParams>();

export const getCoursesFx = createEffect<GetCoursesParams, CoursesList, AxiosError>(
	async (params: GetCoursesParams) => {
		const { data } = await CoursesService.getCourses(params);

		return data;
	}
);

sample({
	clock: getCourses,
	target: getCoursesFx,
});

sample({
	clock: getCoursesFx.doneData,
	target: $getCoursesResponse,
});
