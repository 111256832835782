import { FC } from "react";
import { ROUTES } from "../../../../../../constants/ROUTES";
import s from "./styles.module.sass";
import peoplesSrc from "../../../../../../../public/images/firstsection-students.svg";
import decorSrc from "../../../../../../../public/decor/firstsection-bg-decor.svg";
import Image from "next/image";
import CustomLink from "../../../../../UI/CustomLink";

const FirstSection: FC = () => {
	const linkText = "Выбрать предмет".toUpperCase();
	return (
		<section className={s.firstsection}>
			<div className={s.content}>
				<h1 className={s.h1}>
					БИБЛИОТЕКА
					<br />
					ЗНАНИЙ
				</h1>

				<h2 className={s.h2}>
					Обменивайтесь знаниями: <br className={s.laptop} />
					смотрите курсы, <br className={s.desktop} />
					лекции <br className={s.mobile} />и вебинары <br className={s.laptop} />
					или создавайте собственные <br className={s.desktop} />
					<br className={s.laptop} />
					обучающие продукты <br className={s.laptop} />и программы
				</h2>

				<CustomLink href={ROUTES.ALL_COURSES} text={linkText} size={"firstSection"} />
			</div>

			<div className={s.peoples}>
				<Image src={peoplesSrc} alt="Peoples" />
			</div>

			<div className={s.decor}>
				<Image src={decorSrc} alt="Decor" />
			</div>
		</section>
	);
};

export default FirstSection;
